module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-modal-routing/gatsby-browser.js'),
      options: {"plugins":[],"modalProps":{"className":"modalContent","overlayClassName":"modalOverlay","bodyOpenClassName":"bodyModalOpen"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Potential Cinema Platform","short_name":"Potential","start_url":"/","background_color":"#000","theme_color":"#F06564","display":"minimal-ui","icon":"src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"9f2ed36a922842bcbb81e2d764807d24"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
