import * as React from "react";

import { Link } from "gatsby";
import Button from "src/components/Button";
import facebookLogoPng from "src/images/facebook-logo.png";
import instagramLogoPng from "src/images/instagram-logo.png";
import potentialLogoSvg from "src/images/potential-logo.svg";
import vkLogoPng from "src/images/vk-logo.png";
import footerModuleCss from "./footer.module.css";

const Footer = () => {
  return (
    <footer className={`${footerModuleCss.footerContainer} appWithContainer`}>
      <div className={footerModuleCss.potentialLogoWrapper}>
        <a href="https://potential-platform.com/" target="blank">
          <img src={potentialLogoSvg} alt="logo potential" />
        </a>
      </div>
      <div className={footerModuleCss.connectButton}>
        <Link
          to="/connect-form"
          state={{
            modal: true,
            noScroll: true,
            closeTo:
              typeof window !== `undefined` ? window.location.pathname : "/",
          }}
        >
          <Button>СВЯЗАТЬСЯ С НАМИ</Button>
        </Link>
      </div>
      <div className={footerModuleCss.socialLogosContainer}>
        <div>
          <a href="https://www.instagram.com/potential_film/" target="blank">
            <img src={instagramLogoPng} alt="instagram logo" />
          </a>
        </div>
        <div>
          <a href="https://vk.com/potential_film" target="blank">
            <img src={vkLogoPng} alt="vk logo" />
          </a>
        </div>
        <div>
          <a
            href="https://www.facebook.com/PotentialFilmCompany"
            target="blank"
          >
            <img src={facebookLogoPng} alt="facebook logo" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
